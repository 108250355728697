import "cookieconsent";

frappe.ready(function () {
	window.cookieconsent.initialise({
		palette: {
			popup: {
				background: "#f6f6f6",
				text: "#677788",
			},
			button: {
				background: "#0084e8",
				text: "#ffffff",
			},
		},
		theme: "classic",
		position: "bottom-left",
		content: {
			message:
				'Wir verwenden technisch notwendige Cookies <i class="fas fa-cookie-bite"></i>, um Ihnen die Nutzung unserer Website zu ermöglichen. Durch die Nutzung unserer Website stimmen Sie dem Einsatz von Cookies zu.',
			dismiss: "Alles klar!",
			link: "Datenschutzerklärung",
			href: "/datenschutz",
		},
	});
});
